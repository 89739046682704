import { SettingsManager } from "@viamap/viamap2-common";
import {
  LayerInfo,
  LayerInfoStyling,
  ColorRange,
  SizeRange,
  DataDivisionList,
  ClusteringType,
  DataDivisionType,
  ProjectionType,
  DataDivision,
  MitDataType,
  DivisionStylingType,
  MarkerType,
} from "src/common/managers/Types";
import { DivisionCalculator } from "./DivisionCalculator";
import { GenerateGeomUtils } from "./GenerateGeomUtils";
  import { MitLatLng, MitLayerHandle, MitMap, getMitLayerHandle } from "./MapFacade";
import { MitLayerHandleMaplibre } from "./MapFacadeMaplibre";
import { MaplibreUtils } from "./MaplibreUtils";
import { GenerateGeom } from "./GenerateGeom";
import * as Turf from "@turf/turf";
import circle from '@turf/circle';
import { LayerStyling } from "src/components/LayerStyling";
import { Spider } from "./Spider";
import polylabel from "polylabel"
import { Layout, Properties } from "maplibre-gl";

export class CreateGeoJsonMapLayers {

  static createGeoJsonPointMapLayers(
    layerUniqueName: string,
    defaultColor: string,
    styling: LayerInfoStyling,
    geoJson: Turf.FeatureCollection<Turf.Point, any>,
    layerInfo: LayerInfo
  ): MitLayerHandle {

    if (layerInfo.styling.colorByProperty && layerInfo!.styling.colorByValue!.divisionStylingType !== DivisionStylingType.Custom) {
      

      let value = geoJson.features.map((a) => a.properties[layerInfo.styling.colorByProperty || ""]);
      // Number of divisions should match available sizes
      let useColorRange: number = layerInfo?.styling.colorByValue?.useColorRange ? layerInfo.styling.colorByValue.useColorRange : 0;
      let activeColorRange: ColorRange = GenerateGeom.colorRanges[useColorRange];

      let numberOfDivs: number = activeColorRange.colors.length;
      // Calculate divisions
      let divisionsColor: DataDivisionList;
      // if freezeDivisions and divsions already set then don't change
      if (true && layerInfo!.styling.colorByValue!.divisions) {
        divisionsColor = layerInfo!.styling.colorByValue!.divisions!;
      } else {
        if (isNaN(value.filter(a => a !== undefined)[0])) {
          divisionsColor = DivisionCalculator.calculateDataDivisionsDiscrete(value, numberOfDivs);
        } else {
          divisionsColor = DivisionCalculator.calculateDataDivisions(value, numberOfDivs);
        }
      }
      // Store colors on division
      divisionsColor.list.forEach((obj, idx) => {
        divisionsColor.list[idx].color = activeColorRange.colors[idx];
      });
      layerInfo!.styling.colorByValue!.divisions = divisionsColor;
      layerInfo!.styling.colorByValue!.divisionStylingType = DivisionStylingType.Standard;
    }

    let title = layerInfo.datasetname;
    let dummydivisions: DataDivisionList = {
      type: DataDivisionType.Discrete,
      list: [],
    };
    let divisionsColor: DataDivisionList = dummydivisions;
    let divisionsSize: DataDivisionList = dummydivisions;

    let useColorRange: number =
      layerInfo.styling &&
      layerInfo.styling.colorByValue &&
      layerInfo.styling.colorByValue.useColorRange
        ? layerInfo.styling.colorByValue.useColorRange
        : 0;

    let activeColorRange: ColorRange =
      layerInfo.styling &&
      layerInfo.styling.colorByValue &&
      GenerateGeom.colorRanges?.[useColorRange]
        ? GenerateGeom.colorRanges?.[useColorRange]
        : GenerateGeom.colorRanges[0];

    let sizeRange =
      layerInfo.styling &&
      layerInfo.styling.sizeByValue &&
      layerInfo.styling.sizeByValue.useSizeRange
        ? layerInfo.styling.sizeByValue.useSizeRange
        : 0;
    let sizes: SizeRange = GenerateGeom.sizeRanges[sizeRange];

    let markers = GenerateGeom.markers;

    let displayColor; // The color to display next to layer on LayerList

    // -----------------------------------------------

    let v;
    let admGeo;
    let value: string | undefined = undefined;
    let valueSize: string | undefined = undefined;
    let colorByValue: boolean = !!(layerInfo.styling &&
      layerInfo.styling.colorByValue)
      

    let sizeByValue: boolean = !!(layerInfo.styling &&
      layerInfo.styling.sizeByValue)
      

    console.assert( 
      geoJson.features.length || 0 > 0, "createGeoJsonPointMapLayers, Recived Empty FeatureCollection"
    )

    displayColor = activeColorRange[(layerInfo.styling.colorByValue && layerInfo.styling.colorByValue.useColorRange) ? layerInfo.styling.colorByValue.useColorRange : 0];

    if (layerInfo.styling.colorByProperty) {
      // Get the values
      value = layerInfo.styling.colorByProperty;
      console.assert(layerInfo.styling.colorByValue!.divisions, "Divisions expected");
      divisionsColor = layerInfo.styling.colorByValue!.divisions!;
    }
    // ToDo: remove old stuff
    let divisions:DataDivisionList = divisionsColor;
 
    if (layerInfo.styling.sizeByProperty) {
      // Get the values
      valueSize = layerInfo.styling.sizeByProperty;
      // Number of divisions should match available sizes
      let numberOfDivs:number = sizes.sizes.length;
      // Calculate divisions

      let valueMap = geoJson.features.map((a) => a.properties[valueSize || ""])
      
      divisionsSize = DivisionCalculator.calculateDataDivisions(valueMap, numberOfDivs);
      layerInfo.styling.sizeByValue = {...layerInfo.styling.sizeByValue,divisions:divisionsSize};
    }
 
    value = layerInfo.styling.colorByProperty;
    
    console.assert(
      !colorByValue || (value && value.length && activeColorRange && divisionsColor),
      "showLayerMarkerByValue, Unexpected empty value"
    );
    console.assert(
      !sizeByValue || (valueSize && valueSize.length && sizes && divisionsSize),
      "showLayerMarkerByValue, Unexpected empty value"
    );

    let colors =
      styling &&
      styling.colorByValue &&
      GenerateGeom.colorRanges?.[useColorRange]
        ? GenerateGeom.colorRanges?.[useColorRange]
        : GenerateGeom.colorRanges[0];

    const piePropertyPrefix = "itemCount_";
    const pieColorPrefix = "itemColor_";
    const pieLabelPrefix = "itemLabel_";
    const piePropertyOther = "Other";

    
    let featuresArray = geoJson.features.map((feature,idx) => {
      // default color and radius
      let color = layerInfo!.styling.color ?? defaultColor;
      let radius = SettingsManager.getSystemSetting("defaultMarkerSize", 50);
      let hideMarker = false;
      let colorDivisionId: number = -1; // default, means 'Other'

      // Colour code icons based on value
      if (
        layerInfo.styling.colorByProperty &&
        colors &&
        divisions &&
        layerInfo.styling.colorByProperty
      ) {
        let val = feature.properties[layerInfo.styling.colorByProperty] || "";
        if (divisions.type === DataDivisionType.Discrete) {
          let x = GenerateGeomUtils.getColorByValueDiscrete(
            colors,
            val,
            divisions,
            styling.outsideColorRange
          );
          color = x.color;
          hideMarker = x.hideMarker;
          colorDivisionId = x.divisionId;
        } else {
          let numValue: number;
          let t = typeof val;
          if (typeof val === "string") {
            numValue = Number.parseFloat(val);
          } else {
            numValue = val;
          }
          let x = GenerateGeomUtils.getColorByValueNummeric(
            colors,
            val,
            divisions,
            styling.outsideColorRange
          );
          color = x.color;
          hideMarker = x.hideMarker;
          colorDivisionId = x.divisionId;
        }
      }

      let divIdx;
      // Map value to division to determine radius
      if (sizeByValue && sizes && divisionsSize && valueSize && feature.properties[valueSize]) {
        let valSize = feature.properties[valueSize];
        divIdx = DivisionCalculator.getDivisionId(divisionsSize, feature.properties[valueSize]);
        console.assert(
          divIdx >= 0,
          "Divisions not found for value: " + valSize
        );
        radius = sizes.sizes[divIdx];
      }

      let heightValue:number=0;
      if (styling.heightByValue && layerInfo.styling.heightByProperty) {
        let val = feature.properties[layerInfo.styling.heightByProperty];
        heightValue = val*(styling.heightMultiplier || 1);
      }

      return {
          ...feature,
          id:idx,

          hideMarker: hideMarker,
          type: "Feature",
          properties: {
            ...feature.properties,
            divIdx: divIdx,
            itemcolor: color,
            
            radius: radius,
            [piePropertyPrefix +
            (colorDivisionId === -1
              ? piePropertyOther
              : "" + colorDivisionId)]: 1,
            [pieColorPrefix +
            (colorDivisionId === -1 ? piePropertyOther : "" + colorDivisionId)]:
              color,
            [pieLabelPrefix +
            (colorDivisionId === -1 ? piePropertyOther : "" + colorDivisionId)]:
              (divisions &&
                divisions.list[colorDivisionId] &&
                CreateGeoJsonMapLayers.formatLabelFromDivisionInformation(
                  divisions.list[colorDivisionId]
                )) ||
              "",
            height: heightValue
        }
      }
    }).filter((a) => !a.hideMarker);


    // Spiderify. Check for any overlapping points and change coordinates. And record information about them as properties.
    let {hasOverlaps, convertedArray} = Spider.convertAllOverlaps(featuresArray);
    if (hasOverlaps) {
      featuresArray = convertedArray;
    }

    if (styling.heightByValue && layerInfo.styling.heightByProperty) {
      // convert point to circles (to allow fill-extrusion)
      featuresArray = featuresArray.map((ft) => {
        let radiusKm = ft.properties.radius/10 || 1;
        let c = Turf.circle(ft.geometry, radiusKm, ft.properties);
        return  {
          ...ft,
          type: c.type,
          geometry: c.geometry
        } as any
      });
    }

    let result = getMitLayerHandle();
    let sourceId = GenerateGeom.constructSourceId(layerUniqueName);
    let layerId = GenerateGeom.constructLayerId(
      layerUniqueName,
      "symbol-unclustered-point"
    );
    let layerId2 = GenerateGeom.constructLayerId(
      layerUniqueName,
      "circle-marker-cluster"
    );
    let layerId3 = GenerateGeom.constructLayerId(
      layerUniqueName,
      "circle-cluster-count"
    );
    let layerIdSpiderLines = GenerateGeom.constructLayerId(
      layerUniqueName,
      "line-spider"
    );


    // Clustering
    if (styling.useClustering) {
      if (styling.clusteringType === ClusteringType.Piechart && divisions) {
        // change source id such that we can find all 'pie' sources later.
        sourceId = GenerateGeom.constructSourceId(layerUniqueName, "pie");
        // create a list [0,1,2,3,...(maxidx of division),"Other"]
        let piePropertyPrefixList = Array.from(
          {length: divisions.list.length},
          (value, index) => "" + index
        );
        piePropertyPrefixList.push(piePropertyOther);
        result.addSource(
          sourceId,
          MaplibreUtils.makeSourceSpecPieChart(
            featuresArray,
            piePropertyPrefix,
            pieColorPrefix,
            pieLabelPrefix,
            piePropertyPrefixList
          )
        );
        result.addlayersWithPieCharts(sourceId)
        // Note. A Pie layer is not created. Pie markers are added automatically when source is updated. In MapFacadeMapLibre.ts
      } else {
        result.addSource(
          sourceId,
          MaplibreUtils.makeSourceSpec(featuresArray, true)
        );
        result.addLayer(
          MaplibreUtils.makeLayerClusterMarkers(layerId2, sourceId)
        );
        result.addLayer(
          MaplibreUtils.makeLayerClusterCount(layerId3, sourceId)
        );
      }
    } else {
      result.addSource(
        sourceId,
        MaplibreUtils.makeSourceSpec(featuresArray, false)
      );
    }
    if (styling.heightByValue && layerInfo.styling.heightByProperty) {
      let constructLayerId = GenerateGeom.constructLayerId(layerUniqueName, 'fill-extrusion')
      result.addLayer({
        id: constructLayerId,
        type: 'fill-extrusion',
        source: sourceId ,
        filter: ["!", ["has", "point_count"]],
        layout: {
        },
        paint: {
           "fill-extrusion-height": [ 'get', 'height'],
           'fill-extrusion-color': ['to-color', ['get','itemcolor'], styling.color] ,
           'fill-extrusion-opacity': 0.8
        }
      });
    } else {
      result.addLayer(MaplibreUtils.makeLayerSpecStyled(layerId, sourceId, Boolean(styling.heightByValue)));
    }
    result.addLayerConsumingEvents(layerId);

    // ToDo: Spider: draw lines from each point to the original location
    // if (false && hasOverlaps) {
    //     result.addLayer({
    //       id: layerIdSpiderLines,
    //       type: 'symbol',
    //       source: sourceId,
    //       filter: ['has', 'spiderangle'],
    //       layout: {
    //         'icon-image': "spider-line",
    //         'icon-allow-overlap': true,
    //         'icon-anchor': 'bottom',
    //         'icon-rotate': ['get', 'spiderangle'],
    //         'text-field': ['get', 'spideridx'],
    //         'text-font': ["Noto Sans Regular"],
    //       },
    //     })
    // }

    return result as MitLayerHandle;
  }

  static createGeoJsonSimpleMarker(
    layerUniqueName: string,
    map: MitMap,
    defaultColor: string,
    marker: any,
    styling: LayerInfoStyling,
    geoJson: Turf.FeatureCollection<Turf.Point, any>,
    layerInfo: LayerInfo
   ):MitLayerHandle {
    let pointLayer;
    let result = getMitLayerHandle();

    // paint the items
    
    let iconLoadList: { path: string, iconKey: string }[] = [];

    let useCustomIcon:boolean = layerInfo.styling.markerType === MarkerType.Icon;
    let customIconUrl:string | undefined = undefined;
    if (useCustomIcon) {
      customIconUrl = layerInfo.styling.iconByProperty;
    }
    
    
    
    let featuresArray = geoJson.features.map((feature, idx) => {
      let url: string | undefined = undefined
      if (useCustomIcon && customIconUrl) {
        url = feature.properties[customIconUrl];
        url && iconLoadList.push({path: url, iconKey: url })
      }
      
      let textValue:string="";
      if (styling.textByValue && layerInfo.styling.textByProperty) {
        textValue = styling.textByProperty === "datasetname" ? layerInfo.datasetname || "" : feature.properties[layerInfo.styling.textByProperty];
      }
      let customUrlMix = url ? {"iconUrl":url} : {}

      return {
        ...feature,
        id: idx,
        properties: {
          ...feature.properties,
          ...customUrlMix,
          textValue:textValue,
          textColor: layerInfo.styling.textColor || "blue"
        }
      }
    });

    
    // Spiderify. Check for any overlapping points and change coordinates. And record information about them as properties.
    let {hasOverlaps, convertedArray} = Spider.convertAllOverlaps(featuresArray);
    if (hasOverlaps) {
      featuresArray = convertedArray;
    }

    let sourceId = GenerateGeom.constructSourceId(layerUniqueName);
    let layerId = GenerateGeom.constructLayerId(layerUniqueName,"symbol-unclustered-point");
    let layerId2 = GenerateGeom.constructLayerId(layerUniqueName,"circle-marker-cluster");
    let layerId3 = GenerateGeom.constructLayerId(layerUniqueName,"circle-cluster-count");

    
    // ToDo: virker ikke helt endnu
    // if (marker.svg) {
    //   let img = new Image(20,20)
    //   img.onload = ()=>{
    //     map.getMap().addImage("mit-pin-"+marker.name, img, function done() {
    //             // Force reload of the layer such that the newly loaded icons will be displayed correctly.
    //             map.getMap().setLayoutProperty(layerId, 'visibility', 'none');
    //             map.getMap().setLayoutProperty(layerId, 'visibility', 'visible');
    //   });
    // }
    //   img.src = marker.svg;
    // }

    result.addSource(sourceId, MaplibreUtils.makeSourceSpec(featuresArray, Boolean(styling.useClustering)));
    // Cluster
    if (styling.useClustering) {
      result.addLayer(MaplibreUtils.makeLayerClusterMarkers(layerId2, sourceId));
      result.addLayer(MaplibreUtils.makeLayerClusterCount(layerId3, sourceId));
    }

    result.addLayer(MaplibreUtils.makeLayerSpecSimple(layerId, sourceId, layerInfo.styling, marker.name || 'blue', Boolean(useCustomIcon), styling.iconSize,Boolean(styling.textByValue && layerInfo.styling.textByProperty), styling.textColor, styling.textFont));
    result.addLayerConsumingEvents(layerId);

    
    if (useCustomIcon) {
      GenerateGeom.loadAllImages(map, iconLoadList, () => {
        if (layerInfo.visible) {
          setTimeout(() => {
          map.setLayoutProperty(result,{'visibility': 'none'})
          map.setLayoutProperty(result,{'visibility': 'visible'})
          },200)
        }
      });
    }

    return (result as MitLayerHandle);
  }

  // static createGeoJsonAreaMapLayers(
    
  //   ) {
  //     getNewId(),
  //   v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection
  //   }
  
  static createGeoJsonAreaMapLayers(
    layerUniqueName: string,
    geoJson: Turf.FeatureCollection<Turf.Polygon | Turf.LineString, any>,
    styling: LayerInfoStyling,
    defaultColor: string,
    layerInfo: LayerInfo
    ) : MitLayerHandle {
    let result = getMitLayerHandle();


    let areasWithNoDataColor = styling.areaNoData?.color || SettingsManager.getSystemSetting("areaMapColorForAreasWithNoData",'black');
    let borderColor = styling.areaBorderColor || SettingsManager.getSystemSetting("areaMapColorForBorders", 'white');


    let hideNoDataAreas = !styling.areaNoData?.show;

    let sourceId = GenerateGeom.constructSourceId(layerUniqueName,"geoJson");
    let layerId = GenerateGeom.constructLayerId(layerUniqueName,"fill");
    let layerId2 = GenerateGeom.constructLayerId(layerUniqueName,"line");
    let layerIdText = GenerateGeom.constructLayerId(layerUniqueName,"fill-text");
    let layerId3 = GenerateGeom.constructLayerId(layerUniqueName,"symbol");

    let useColorRange: number = layerInfo.styling.colorByValue?.useColorRange || 0
    let colors = styling.colorByValue && GenerateGeom.colorRanges?.[useColorRange] || GenerateGeom.colorRanges[0];


    let dummydivisions: DataDivisionList = {
      type: DataDivisionType.Discrete,
      list: [],
    };
    let divisions:DataDivisionList = dummydivisions;

    if (layerInfo.styling.colorByProperty && layerInfo!.styling.colorByValue!.divisionStylingType !== DivisionStylingType.Custom) {
      // Number of divisions should match available sizes
      
      let activeColorRange = colors;
      let numberOfDivs: number = activeColorRange.colors.length;

      if (true && layerInfo!.styling.colorByValue!.divisions) {
        divisions = layerInfo!.styling.colorByValue!.divisions!;
      } else {
        let value = geoJson.features.map((a) => a.properties[layerInfo.styling.colorByProperty || ""]);
        if (isNaN(value.filter(a => a !== undefined)[0])) {
          divisions = DivisionCalculator.calculateDataDivisionsDiscrete(value, numberOfDivs);
        } else {
          divisions = DivisionCalculator.calculateDataDivisions(value, numberOfDivs);
        }

        divisions.list.forEach((obj, idx) => {
          divisions.list[idx].color = activeColorRange.colors[idx];
        });
        layerInfo!.styling.colorByValue!.divisions = divisions;
        layerInfo!.styling.colorByValue!.divisionStylingType = DivisionStylingType.Standard;
      }

    }

    if (layerInfo.styling.colorByProperty) {
      console.assert(layerInfo.styling.colorByValue!.divisions, "Divisions expected");
      divisions = layerInfo.styling.colorByValue!.divisions!;
    }
    // ToDo: remove old stuff

    const mul = styling.lineDashMultitude ?? 5
    const rat = styling.lineDashRatio ?? 1
    const dashArray = rat === 1 ? {} : {'line-dasharray' : [rat*mul, (1-rat)*mul]}

    let featuresArray = geoJson.features.map((feature, idx) => {
      let val: any = undefined;

      let color: string | undefined = undefined;
      let hideArea;
      if (feature.properties["VIAMAP_NO_DATA_FOR_AREA"]) {
        color = areasWithNoDataColor
      } else if (layerInfo.styling.colorByProperty) {
        val = feature.properties[layerInfo.styling.colorByProperty]
        if (divisions.type === DataDivisionType.Discrete) {
          let x = GenerateGeomUtils.getColorByValueDiscrete(colors, val, divisions, styling.outsideColorRange, styling.areaNoData);
          color = x.color;
          hideArea = x.hideMarker;
        } else {
          let x = GenerateGeomUtils.getColorByValueNummeric(colors, val, divisions, styling.outsideColorRange);
          color = x.color;
          hideArea = x.hideMarker;
        }
      }

      let heightValue:number=0;
      if (styling.heightByValue && layerInfo.styling.heightByProperty) {
        let val = feature.properties[layerInfo.styling.heightByProperty];
        heightValue = val*(styling.heightMultiplier || 1);
      }

      if (hideArea || (hideNoDataAreas && feature.properties["VIAMAP_NO_DATA_FOR_AREA"])) {
        return null 
      }

      return {
        ...feature,
        id: idx,
        properties: {
          ...feature.properties,
          ...this.MixInProperty("fillcolor", color),
          height:heightValue
        }
      }
    }).filter(a => a) as any[]

    
    if (styling.textByProperty) {
      featuresArray = [...featuresArray,...featuresArray.map((a) => {
        if (styling.textByProperty! in a.properties || styling.textByProperty == "datasetname") {
          if (a.geometry.type == "LineString") {
            return []
          }
          if ((a.geometry.type as any) == "MultiPolygon") {
            return a.geometry.coordinates.map((coords) => {
              let pos = polylabel(coords, 0.001);
              return Turf.point([pos[0],pos[1]], {textToDisplay: styling.textByProperty === "datasetname" ? layerInfo.datasetname || "" : a.properties[styling.textByProperty || ""]})
            });
          }
          let pos = polylabel(a.geometry.coordinates, 0.001);
          return Turf.point([pos[0], pos[1]], {textToDisplay: styling.textByProperty === "datasetname" ? layerInfo.datasetname || "" : a.properties[styling.textByProperty || ""]}, {id: undefined})
        }
        return []
      }).flat()]
    }

    result.addSource(sourceId, {
      type: "geojson",
      data: ({type:"FeatureCollection", "features":featuresArray}) as any,
      generateId: false,
    });

    if (styling.heightByValue && layerInfo.styling.heightByProperty) {
      layerId = GenerateGeom.constructLayerId(layerUniqueName,"fill-extrusion");
      result.addLayer({
        id: layerId,
        type: 'fill-extrusion',
        source: sourceId ,
        filter: ["!", ["has", "point_count"]],
        layout: {
        },
        paint: {
           "fill-extrusion-height": [ 'get', 'height'],
           'fill-extrusion-color': ['to-color', ['get','fillcolor'], styling.areaFillColor || styling.color] ,
           'fill-extrusion-opacity': 0.8
        }
      });

      result.addLayerConsumingEvents(layerId);
    } else {
      result.addLayer({
        id: layerId,
        type: "fill",
        source: sourceId,
        layout: {
        },
        'paint': {
          'fill-color': ['to-color', ['get','fillcolor'], styling.areaFillColor || styling.color] ,
          'fill-opacity': ['number', ['get', 'fillopacity'], ['get', 'opacity'] , styling.opacity ?? 1],
        },
        "filter": ["==", "$type", "Polygon"],
      });
      result.addLayer({
        id: layerId2,
        type: "line",
        source: sourceId,
        layout: {
        },
        'paint': {
          'line-color': ['to-color', ['get', 'linecolor'], styling.lineColor ?? styling.color?.replace(/[\d\.]+\)$/g, '1)') ?? 1],
          'line-width': ['number', ['get', 'weight'], styling.lineWidth ?? SettingsManager.getSystemSetting("geoJSONLineWeight", 4)],
          'line-opacity': ['number', ['get', 'lineopacity'], ['get', 'opacity'] ,styling.lineOpacity ?? styling.opacity ?? 1],
          ...dashArray
        },
        "filter":  ["!=", "$type", "Point"],
      });
      result.addLayer({
        id: layerIdText,
        type: "symbol",
        source: sourceId,
        "paint": {
          "text-color": styling.textColor || "#454659",
          "text-halo-color": "#fff",
          "text-halo-width": 1.5
        },
        "layout": {
          "text-field": ["get","textToDisplay"],
          "text-allow-overlap": false,
          "text-ignore-placement": false,
          "text-font": [
            styling.textFont ||
            "Noto Sans Regular"
          ]
        },
        "filter": ["all",["==", "$type", "Point"],["has","textToDisplay"]]
      })
      result.addLayerConsumingEvents(layerId);
    }
    return(result as MitLayerHandle);
 }

  static MixInProperty(propertyName: string, value:any) {
    return value !== undefined ? {[propertyName]:value} : {}
  }


  static formatLabelFromDivisionInformation(div: DataDivision): string {
    let result = "";
    if (div) {
      if (div.label) {
        result = div.label;
      } else {
        if (div.value) {
          // Discrete values
          result = div.value;
        } else {
          let op, text;
          // Numeric Values
          if (div.from === null) {
            op = "<=";
            text = div.to;
          } else {
            if (div.to === null) {
              op = ">";
              text = div.from;
            } else {
              op = "<=";
              text = div.to;
            }
          }
          result = op + " " + text;
        }
      }
    }
    return result;
  }
}
