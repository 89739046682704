import * as React from "react";
import { Card } from "react-bootstrap";
import { Localization, SettingsManager } from "@viamap/viamap2-common";
import {
  ColorRangeList,
  SizeRangeList,
  LayerType,
  LayerInfo,
  DataDivisionType,
  ColorByValueStyling,
  DivisionStylingType,
  OtherLegendSpec,
  LayerHierarchy,
} from "../common/managers/Types";
import {
  ColorBoxEditable,
  ColorBox,
  MitCount,
} from "./ComponentUtils";
import { LegendScale } from "./LegendScale";
import Glyphicon from "@strongdm/glyphicon";
import { MapitUtils } from "src/managers/MapitUtils";
import { OldHierarchy } from "src/managers/OldHierarchy";

type Props = {
  showWindow: number;
  layers: LayerInfo[];
  layerHiearchy?: LayerHierarchy;
  sizeRanges: SizeRangeList;
  colorRanges: ColorRangeList;
  callBackOnStyleChange: (layerInfo: LayerInfo) => number;
  callBackToHideWindow: () => void;
  otherLegends: { [legendId: string]: OtherLegendSpec };
};

export function Legend(props: Props) {
  function handleDismiss() {
    props.callBackToHideWindow();
  }

  function onClickCard(evt: any) {
    props.callBackToHideWindow();
  }

  function changeSimpleStylingPropertiesAndReStyleMap(
    layerInfo: LayerInfo,
    newStylingProps: {}
  ) {
    let newLayerInfo = {
      ...layerInfo,
      styling: { ...layerInfo.styling, ...newStylingProps },
    };
    props.callBackOnStyleChange(newLayerInfo);
  }

  function callBackOnStyleChange(
    layerInfo: LayerInfo,
    newStyle: ColorByValueStyling
  ) {
    layerInfo.styling.colorByValue!.divisions = newStyle.divisions;
    layerInfo.styling.colorByValue!.divisionStylingType =
      DivisionStylingType.Custom;
    props.callBackOnStyleChange(layerInfo);
  }

  function verticalColorLegend(layerInfo: LayerInfo, showTitle: boolean) {
    console.assert(layerInfo.styling.colorByValue, "colorByValue expected");
    console.assert(props.colorRanges, "ColorRanges expected");

    let renderTitle;
    if (showTitle) {
      let column = layerInfo.styling.colorByProperty;
      let title = column;

      renderTitle = (
        <div className="LegendRow" style={{ width: "100px", textAlign: "left", fontSize: "7pt" }}>
          <div className={"divSpan-5"}>
          {title}
          </div>
        </div>
      );
    }
    return (
      <>
        {renderTitle}
        <LegendScale
          allowEdit={
            SettingsManager.getSystemSetting("allowEditingOfDivisions") &&
            !layerInfo.readonly
          }
          colorRanges={props.colorRanges}
          style={layerInfo.styling.colorByValue!}
          callBackOnStyleChange={(newStyle) => {
            callBackOnStyleChange(layerInfo, newStyle);
          }}
          dataValues={layerInfo.geoJson.features.map(
            (a) => a.properties[layerInfo.styling.colorByProperty || ""]
          )}
          otherValue={layerInfo.styling.outsideColorRange}
          setOtherValue={(a) => {
            props.callBackOnStyleChange({...layerInfo, styling:{...layerInfo.styling, outsideColorRange: a}});
          }}
          showElementCounts={Boolean(layerInfo.styling.showElementCounts)}
        />
      </>
    );
  }

  function verticalSizeLegend(layerInfo: LayerInfo, showTitle: boolean): any[] {
    console.assert(layerInfo.styling.sizeByValue, "sizeByValue expected");
    console.assert(props.sizeRanges, "sizeRanges expected");

    let renderDivRows =
      layerInfo.styling &&
      layerInfo.styling.sizeByValue &&
      layerInfo.styling.sizeByValue.divisions &&
      layerInfo.styling.sizeByValue.divisions.list.map((div, idx) => {
        let size =
          props.sizeRanges[layerInfo.styling.sizeByValue!.useSizeRange || 0]
            .sizes[idx];
        size = size / 2;
        let bgColor = layerInfo.styling.color || "grey";
        let op;
        let text;
        if (div.from === null) {
          op = "<=";
          text = div.to;
        } else {
          if (div.to === null) {
            op = ">";
            text = div.from;
          } else {
            op = "<=";
            text = div.to;
          }
        }
        return (
          <div className="LegendRow" key={"tx" + idx}>
            <div>
              <div
                key={"ty" + idx}
                className="int-bot mit-color-box"
                style={{
                  background: bgColor,
                  borderRadius: "50%",
                  width: size,
                  height: size,
                }}
              />
            </div>
            <div>{op}</div>
            <div className={"divSpan-2"} style={{ textAlign: "left" }}>
              {text}{" "}
              <MitCount
                show={Boolean(layerInfo.styling.showElementCounts)}
                count={div.count}
              />
            </div>
          </div>
        );
      });
    if (showTitle) {
      let column = layerInfo.styling.sizeByProperty;
      let title = column;
      return [
        <div className="LegendRow" key={"tty"}>
          <div className="divSpan-3" style={{ textAlign: "left" }}>
            {title}
          </div>
        </div>,
        renderDivRows,
      ];
    } else {
      return renderDivRows || [];
    }
  }

  function renderSingleColorLegend(
    readOnly: boolean,
    label: string,
    value: string,
    onCommit: (color: string) => any,
    onCancel?: () => any
  ) {
    let text = "[" + label + "]";
    let op = "";
    let RenderColorBox =
      readOnly === true ? (
        <ColorBox color={value} />
      ) : (
        <ColorBoxEditable
          color={value}
          onCommit={onCommit}
          onCancel={onCancel}
        />
      );
    return (
      <div className="LegendRow" key={"singleColor" + label}>
        <div>{RenderColorBox}</div>
        <div>{op}</div>
        <div className="divSpan-2" style={{ textAlign: "left" }}>
          {text}
        </div>
      </div>
    );
  }

  function otherValueDataLegend(layerInfo: LayerInfo) {
    let value =
      layerInfo.styling.outsideColorRange ||
      SettingsManager.getSystemSetting("mapColorForOtherDataValue", "cyan");
    let text = Localization.getText("Other data");
    let onCommit = (c) =>
      changeSimpleStylingPropertiesAndReStyleMap(layerInfo, {
        valueOutsideRangeColor: c,
      });
    return renderSingleColorLegend(layerInfo.readonly, text, value, onCommit);
  }

  function areasWithNoDataLegend(layerInfo: LayerInfo) {
    let value =
      layerInfo.styling.areaNoData?.color ||
      SettingsManager.getSystemSetting(
        "areaMapColorForAreasWithNoData",
        "black"
      );
    let text = Localization.getText("No data");
    let onCommit = (c) =>
      changeSimpleStylingPropertiesAndReStyleMap(layerInfo, {
        areasWithNoDataColor: c,
      });
    return renderSingleColorLegend(layerInfo.readonly, text, value, onCommit);
  }

  function areaFillColorLegend(layerInfo: LayerInfo) {
    let value =
      layerInfo.styling.areaFillColor ||
      SettingsManager.getSystemSetting("areaMapDefaultFillColor", "black");
    let text = Localization.getText("Area Fill Color");
    let onCommit = (c) =>
      changeSimpleStylingPropertiesAndReStyleMap(layerInfo, {
        areaFillColor: c,
      });
    return renderSingleColorLegend(layerInfo.readonly, text, value, onCommit);
  }

  let layerx;
  let anyLayersToDisplay: boolean = false;
  if (props.layers.length > 0) {
    let layersSorted = props.layers.sort(MapitUtils.sortByLayerId);
    if (props.layerHiearchy) {
      const ids = OldHierarchy.getAllIdOrderedTopBottom(props.layerHiearchy);
      layersSorted = ids
        .map((a) => props.layers.find((b) => b.layerId === a))
        .filter((a) => a) as LayerInfo[];
    }
    layerx = layersSorted.map((layerInfo: LayerInfo, index) => {
      if (layerInfo.visible) {
        anyLayersToDisplay = true;
        let idx = "collapse" + index;
        let name = layerInfo.datasetname;

        const layerIcon =
          layerInfo.type === LayerType.GeoJSON_Point ||
          layerInfo.type === LayerType.PointWGS84 ? (
            <Glyphicon
              glyph="map-marker"
              style={{ color: layerInfo!.styling!.color }}
            />
          ) : (
            <Glyphicon
              glyph="picture"
              style={{ color: layerInfo!.styling!.color }}
            />
          );
        let valueColorLegend = layerInfo!.styling!.colorByProperty
          ? verticalColorLegend(layerInfo, true)
          : null;
        let valueSizeLegend = layerInfo!.styling!.sizeByProperty
          ? verticalSizeLegend(layerInfo, true)
          : [];
        let noDataLegend =
          MapitUtils.isAreaLayer(layerInfo.type) &&
            layerInfo.styling.areaNoData?.show
            ? areasWithNoDataLegend(layerInfo)
            : null;
        let areaFillColorLegendValue =
          MapitUtils.isAreaLayer(layerInfo.type) &&
          !layerInfo!.styling!.colorByProperty
            ? areaFillColorLegend(layerInfo)
            : null;
        let otherValueDataLegendValue =
          layerInfo!.styling!.colorByValue &&
          layerInfo!.styling!.colorByValue!.divisions &&
          layerInfo!.styling!.colorByValue!.divisions!.type ===
            DataDivisionType.Discrete &&
          layerInfo!.styling!.colorByValue!.divisions!.otherValuesCount &&
          (layerInfo!.styling!.colorByValue!.divisions!.otherValuesCount || 0) > 0
            ? null && otherValueDataLegend(layerInfo)
            : null;
        return (
          <div key={idx}>
            <div
              style={{
                maxWidth: "100%",
                textAlign: "left",
                fontSize: "7pt",
                display: "flex",
                gap: "1em",
              }}
            >
              <div>{layerIcon}</div>
              {name}
              <MitCount
                show={Boolean(layerInfo!.styling!.showElementCounts)}
                count={
                  Boolean(layerInfo!.styling!.showElementCounts)
                    ? MapitUtils.getElementCount(layerInfo)
                    : 0
                }
              />
            </div>
            {valueColorLegend ? <div className={"TableLegend"} style={{ paddingLeft: "2.5em" }}><div className="tBodyDiv">
              {valueColorLegend}
            </div>
            </div> : null}
            {otherValueDataLegendValue ? <div className={"TableLegend"} style={{ paddingLeft: "2.5em" }}><div className="tBodyDiv">
              {otherValueDataLegendValue}
            </div>
            </div> : null}
            {valueSizeLegend ? <div className={"TableLegend"} style={{ paddingLeft: "2.5em" }}><div className="tBodyDiv">
              {valueSizeLegend}
            </div>
            </div> : null}
            {noDataLegend ? <div className={"TableLegend"} style={{ paddingLeft: "2.5em" }}><div className="tBodyDiv">
              {noDataLegend}
            </div>
            </div> : null}
            {areaFillColorLegendValue ? <div className={"TableLegend"} style={{ paddingLeft: "2.5em" }}><div className="tBodyDiv">
              {areaFillColorLegendValue}
            </div>
            </div> : null}
          </div>
        );
      }
      return null;
    });
  } else {
    layerx = null;
  }

  let closeButton = (
    <Glyphicon
      style={{ float: "right" }}
      glyph="remove"
      onClick={() => handleDismiss()}
      title={Localization.getText("Hide")}
    />
  );

  let otherLegends = Object.keys(props.otherLegends).map((lgd, idx) => {
    let ol = props.otherLegends[lgd];
    if (ol.showLegend()) {
      anyLayersToDisplay = true;
      return <div key={lgd}>{ol.renderLegend()}</div>;
    }
  });

  if (props.showWindow && anyLayersToDisplay) {
    return (
      <div id="mit-legend" style={{ isolation: "isolate" }}>
        <Card
          style={{ borderRadius: "0px", margin: "0px" }}
          className="Flat-Card"
        >
          <Card.Header>
            <Card.Title onClick={(e) => onClickCard(e)}>
              {Localization.getText("Legend")}
              {closeButton}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {layerx}
            {otherLegends}
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    return null;
  }
}
