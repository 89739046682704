import * as React from 'react';
import { AppMessagesContext, Logger, SettingsManager, actionSetErrorMessage } from '@viamap/viamap2-common';
import ReactDOMServer from 'react-dom/server';
import { LayerInfo, DataDivisionList, DataDivisionType, ColorRange, SizeRange, ReferenceGeomFeature, MitDataType, PictureSize, PictureLayoutType, LabelButton, LayerType, ProjectionType, LayerVariabilityType,  MarkerType, MapInfo, ImportTransformationType, ImportTransformationAction, ImportTransformation } from 'src/common/managers/Types';
import { Utils } from '@viamap/viamap2-common';
import { getNewId, PopUpFeatureAvailability } from 'src/states/MapitState';
import { GenerateGeom } from '../managers/GenerateGeom';
import { Localization } from "@viamap/viamap2-common";
import { MitMap, MitLatLng, MitLayerHandle } from '../managers/MapFacade';
import { GeoJsonImportFormat } from '../managers/Persistence';
import { ReferenceGeomComposite } from '../managers/ReferenceGeomComposite';
import { SheetFunc } from '../managers/SheetFunc';
import { DivisionCalculator } from 'src/managers/DivisionCalculator';
import { Feature, FeatureType } from 'src/states/ApplicationState';
import { GenerateGeomUtils } from 'src/managers/GenerateGeomUtils';
import chroma from 'chroma-js';
import * as turf from '@turf/turf';
import { FeaturesAccessibleByMode } from 'src/states/ApplicationStateFeatureMapping';
import { CreateGeoJsonMapLayers } from 'src/managers/CreateGeoJsonMapLayers';

export class MapitLayerFactory {
   
 static funcLabelHeader = (dummy:any) => "";
 static funcFormatCell = (val:any, excelFormat?:string) => {
  return SheetFunc.formatCell(val, excelFormat);
};
static funcLabelExtractDataPairs = (layerInfo:LayerInfo, index:number) => {
  let labelPairs= {};
  let key:MitDataType;

  // Display all selected labels. Up to 20 in all
  [MitDataType.Label,
  MitDataType.Label2,
  MitDataType.Label3,
  MitDataType.Label4,
  MitDataType.Label5,
  MitDataType.Label6,
  MitDataType.Label7,
  MitDataType.Label8,
  MitDataType.Label9,
  MitDataType.Label10,
  MitDataType.Label11,
  MitDataType.Label12,
  MitDataType.Label13,
  MitDataType.Label14,
  MitDataType.Label15,
  MitDataType.Label16,
  MitDataType.Label17,
  MitDataType.Label18,
  MitDataType.Label19,
  MitDataType.Label20,
  ].forEach((obj,idx) => {
    key=obj;
    if (layerInfo.styling.labelTabs && layerInfo.analysisResult && layerInfo.data && layerInfo.columnMapping[key] && layerInfo.data[key]) {
      labelPairs = {...labelPairs, 
        [layerInfo.analysisResult.columnNames[layerInfo.columnMapping[key]-1]]
        :
        MapitLayerFactory.funcFormatCell(layerInfo.data[key][index], layerInfo.analysisResult.columns[layerInfo.columnMapping[key]-1].excelCellFormat)
      };
    }    
  });

  return labelPairs;
};

 static funcLabelRaw = (mapInfo: MapInfo, labelPairs:any, jsonFeature?:ReferenceGeomFeature) => {
    let header:string=jsonFeature ? MapitLayerFactory.funcLabelHeader(jsonFeature) : "";
    let key:MitDataType;

    let imageClassName = mapInfo.popUpPictureSize === PictureSize.Small ? "mit-label-image-small" : 
      (mapInfo.popUpPictureSize === PictureSize.Large ? "mit-label-image-large" : "mit-label-image-medium");
    
    let renderValuesLeft:JSX.Element[]=[];
    let renderValuesRight:JSX.Element[]=[];
    let renderValues:JSX.Element[] = labelPairs && Object.keys(labelPairs).map((key2, idx) => {
      let val:string = labelPairs[key2];
      let elm:JSX.Element;
      let popUpPictureLayoutType:PictureLayoutType = mapInfo.popUpPictureLayout;
      if (val && (val.toString().toLowerCase().startsWith("http") || val.toString().toLowerCase().startsWith("blob:http"))) {
        let img = document.createElement('img');
        img.src = val;
        img.className = imageClassName;
        let imgSer = new XMLSerializer().serializeToString(img);
        imgSer = imgSer.replace("mg ", `mg ondragstart="return false" onclick="window.open(src, '_blank');" `);
        elm = (
          <tr key={"labels"+idx}><td className="mit-label-image-border" colSpan={2} dangerouslySetInnerHTML={{ __html: imgSer}} /></tr>
        );
        switch (popUpPictureLayoutType) {
          case PictureLayoutType.Right:
            renderValuesRight.push(elm);
            break;
          case PictureLayoutType.Left:
          case PictureLayoutType.Center:
            renderValuesLeft.push(elm);
            break;
          default:
            throw Utils.createErrorEventObject("Unknown PictureLayoutType: "+mapInfo.popUpPictureLayout);
        }
      } else {
        let valElm;
        let valString = val && val.toString() && val.toString().trim();
        if (valString && valString[0]==="<" && valString[valString.length-1] === ">") {
          // It is likely embedded html. Show embedded html
          valElm = (
            <div dangerouslySetInnerHTML={{__html: val }}/>
          );
          elm = (
            <tr key={"labels"+idx}><td colSpan={2}>{valElm}</td></tr>
          );
        } else {
          valElm = val;
          elm = (
            <tr key={"labels"+idx}><td>{key2}</td><td>{valElm}</td></tr>
          );          
        }

        switch (popUpPictureLayoutType) {
          case PictureLayoutType.Left:
            renderValuesRight.push(elm);
            break;
          case PictureLayoutType.Right:
          case PictureLayoutType.Center:
            renderValuesLeft.push(elm);
            break;
          default:
            throw Utils.createErrorEventObject("Unknown PictureLayoutType: "+mapInfo.popUpPictureLayout);
        }
      }
      return <React.Fragment key={idx+"labels"}>{elm}</React.Fragment>
    });

    if (!renderValues || renderValues.length===0) {
      renderValues = [
        (
          <tr key={"TEST"}>
            <td colSpan={2} style={{textAlign:"center"}}>
                {Localization.getText("No labels defined")}
            </td>
          </tr>
        )
      ];
    } else {
      renderValues = [(
        <tr key={"TEST2"}>
        <td colSpan={2} style={{textAlign:"center"}}>
          <table className="mit-popup-label-subtable">
          <tbody>
          <tr>
            <td>
            <table className="mit-popup-label-subtable2">
            <tbody>
            {renderValuesLeft}
            </tbody>
            </table>
            </td>
            <td>
            <table className="mit-popup-label-subtable2">
            <tbody>
            {renderValuesRight}
            </tbody>
            </table>
            </td>
          </tr>
          </tbody>
          </table>
        </td>
        </tr>
      )];
    }

    let renderTable = (
      <div>
      <table className="mit-popup-label">
      <thead><tr><td colSpan={2} style={{textAlign:"center"}}><b>{header}</b></td></tr></thead>
      <tbody>
      {renderValues}
      </tbody>
      </table>
      </div>
    );
    return (renderTable);
  };

  static funcLabel = (mapInfo: MapInfo, layerInfo:LayerInfo, index:number, jsonFeature?:ReferenceGeomFeature) => {
    let labelPairs= MapitLayerFactory.funcLabelExtractDataPairs(layerInfo, index);
    return (ReactDOMServer.renderToString(MapitLayerFactory.funcLabelRaw(mapInfo, labelPairs, jsonFeature)));
  };
    /**
   * Add a new layer
   * 
   * @param map The map object
   * @param layerInfo The layerInfo for the layer to create
   */
  static createADataLayer(map: MitMap, layerInfo:LayerInfo, mapInfo: MapInfo, 
    featureAvailability:PopUpFeatureAvailability):{layerHandle?:MitLayerHandle, layerInfo:LayerInfo} {

      // const {dispatch:appMessageDispatch} = React.useContext(AppMessagesContext);
   let title=layerInfo.datasetname;
   let dummydivisions:DataDivisionList = {type:DataDivisionType.Discrete,list:[]};
   let divisionsColor:DataDivisionList = dummydivisions;
   let divisionsSize:DataDivisionList = dummydivisions;

   let useColorRange:number = layerInfo.styling && layerInfo.styling.colorByValue && layerInfo.styling.colorByValue.useColorRange ? layerInfo.styling.colorByValue.useColorRange : 0;
   let activeColorRange:ColorRange = layerInfo.styling && layerInfo.styling.colorByValue && GenerateGeom.colorRanges?.[useColorRange] ? GenerateGeom.colorRanges?.[useColorRange] : GenerateGeom.colorRanges[0];

   let sizeRange = (layerInfo.styling && layerInfo.styling.sizeByValue && layerInfo.styling.sizeByValue.useSizeRange) ? layerInfo.styling.sizeByValue.useSizeRange : 0;
   let sizes:SizeRange = GenerateGeom.sizeRanges[sizeRange];

   let markers = GenerateGeom.markers;    

   let displayColor; // The color to display next to layer on LayerList
   
   // -----------------------------------------------

   let v;
   let admGeo;
   let value:any[]=[];
   let value2:any[]=[];
   let valueHeight:any[]=[];
   let colorByValue:boolean = Boolean(layerInfo.styling && layerInfo.styling.colorByValue && layerInfo.styling.colorByProperty !== undefined);
   let sizeByValue:boolean = (layerInfo.styling && layerInfo.styling.sizeByValue && layerInfo.styling.sizeByValue.dataColumn && layerInfo.styling.sizeByValue.dataColumn !== undefined) as boolean;
   let heightByValue:boolean = (layerInfo.styling && layerInfo.styling.heightByValue && layerInfo.styling.heightByValue.dataColumn && layerInfo.styling.heightByValue.dataColumn !== undefined) as boolean;
   let hideNoDataAreas = (layerInfo.styling && layerInfo.styling.areaNoData) ? layerInfo.styling.areaNoData?.show : false;
   let func: (codes:any[], jsonFeature:ReferenceGeomFeature) => number;
   let funcLabelHeader: (jsonFeature2:ReferenceGeomFeature) => string;
   let funcLabel: (index:number, jsonFeature?:ReferenceGeomFeature)=>string;
   let funcLabelRaw: (index:number, jsonFeature?:ReferenceGeomFeature)=>React.ReactElement<any>;

    function getDataArrayByPropertyName(layerInfo:LayerInfo, propertyName:string):any[] {
      return layerInfo.geoJson.features.map((ft) => {return ft.properties[propertyName]});      
    }

   // static createLabelPairs(index:number):any {
   //     let labelPairs= {};
   //     let key:MitDataType;
 
   //     // Todo: show in tabs
 
   //     // Display all selected labels. Up to 20 in all
   //     [MitDataType.Label,
   //     MitDataType.Label2,
   //     MitDataType.Label3,
   //     MitDataType.Label4,
   //     MitDataType.Label5,
   //     MitDataType.Label6,
   //     MitDataType.Label7,
   //     MitDataType.Label8,
   //     MitDataType.Label9,
   //     MitDataType.Label10,
   //     MitDataType.Label11,
   //     MitDataType.Label12,
   //     MitDataType.Label13,
   //     MitDataType.Label14,
   //     MitDataType.Label15,
   //     MitDataType.Label16,
   //     MitDataType.Label17,
   //     MitDataType.Label18,
   //     MitDataType.Label19,
   //     MitDataType.Label20,
   //     ].forEach((obj,idx) => {
   //       key=obj;
   //       if (layerInfo.styling.labelTabs && layerInfo.analysisResult && layerInfo.data && layerInfo.columnMapping[key] && layerInfo.data[key]) {
   //         labelPairs = {...labelPairs, 
   //           [layerInfo.analysisResult.columnNames[layerInfo.columnMapping[key]-1]]
   //           :
   //           funcFormatCell(layerInfo.data[key][index], layerInfo.analysisResult.columns[layerInfo.columnMapping[key]-1].excelCellFormat)
   //         };
   //       }    
   //     });
   //     return labelPairs;
   // }



// Code for images
//    <tr key="zulu"><td>Image</td><td><Image src="https://estatemedia.dk/dk/wp-content/uploads/2018/01/vibenshuset-lintrup-norgart-facade-692x431.png" responsive={true}/></td></tr>
//
//         for iframing check https://www.npmjs.com/package/react-iframe

// for multiselect labels use https://www.npmjs.com/package/react-bootstrap-multiselect
//
//  

  //  displayColor = activeColorRange[(layerInfo.styling.colorByValue && layerInfo.styling.colorByValue.useColorRange) ? layerInfo.styling.colorByValue.useColorRange : 0];

   if (colorByValue) {
     // Get the values
     console.assert(layerInfo.styling.colorByProperty, "colorByProperty expected");
     value = getDataArrayByPropertyName(layerInfo, layerInfo.styling.colorByProperty!);
     console.assert(layerInfo.styling.colorByValue!.divisions, "Divisions expected");
     divisionsColor = layerInfo.styling.colorByValue!.divisions!;
   }
   // ToDo: remove old stuff
   let divisionsColorToDisplay:DataDivisionList = divisionsColor;

   if (sizeByValue) {
     // Get the values
     value2 = getDataArrayByPropertyName(layerInfo, layerInfo.styling.sizeByProperty!);;
     // Number of divisions should match available sizes
     let numberOfDivs:number = sizes.sizes.length;
     // Calculate divisions
     divisionsSize = DivisionCalculator.calculateDataDivisions(value2, numberOfDivs);
   } 

   if (heightByValue) {
    // Get the values
    valueHeight = getDataArrayByPropertyName(layerInfo, layerInfo.styling.heightByProperty!);;
  } 


   let callbackOnSpatialSelection = featureAvailability[Feature.SpatialExportGeoJSON] && featureAvailability[Feature.SpatialExportGeoJSON].callback;
   
   let defaultEqualityFunctionByCode = (codes:any[], jsonFeature:ReferenceGeomFeature) => {
     return GenerateGeom.findIndexWithEqualityFunc(
       codes, 
       ReferenceGeomComposite.selectCodeElement(layerInfo.type, jsonFeature), 
       ReferenceGeomComposite.equalityFunc(layerInfo.type));
   };  
   let defaultEqualityFunctionByName = (codes:any[], jsonFeature:ReferenceGeomFeature) => {
     return GenerateGeom.findIndexWithEqualityFunc(
       codes, 
       ReferenceGeomComposite.selectNameElement(layerInfo.type, jsonFeature), 
       ReferenceGeomComposite.equalityFunc(layerInfo.type));
      };  
      let defaultAreaLabelHeaderFunction = (jsonFeature:ReferenceGeomFeature) => {
        return ReferenceGeomComposite.getLabelHeader(layerInfo.type, jsonFeature, mapInfo.popUpLayout);
      };
      
  try {

    let labelButtonList: LabelButton[] = []
    let funcLabel = (index:number) => ""; // MapitLayerFactory.funcLabel(mapInfo, layerInfo, index);

     let layerHandle:MitLayerHandle;
     switch(layerInfo.type) {
         case LayerType.PointWGS84:
         case LayerType.PointUTM32:

           let pType:ProjectionType;
           let latOrX:any[];
           let lonOrY:any[];
           if(layerInfo.type === LayerType.PointWGS84) {
             pType=ProjectionType.WGS84;
             latOrX=layerInfo.data![MitDataType.Coord_WGS84_Lat];
             lonOrY=layerInfo.data![MitDataType.Coord_WGS84_Lon];
           } else { 
             // Then it is LayerType.PointUTM32
             pType=ProjectionType.UTM32;
             latOrX=layerInfo.data![MitDataType.Coord_UTM32_X];
             lonOrY=layerInfo.data![MitDataType.Coord_UTM32_Y];
           }

           funcLabelHeader = (dummy) => { return "";};
 
           if (colorByValue || sizeByValue || heightByValue) {
             layerHandle = GenerateGeom.showLayerMarkerByValue(
                 getNewId(),
                 map,
                 pType,
                 latOrX,
                 lonOrY,
                 layerInfo.styling.color ? layerInfo.styling.color : "blue",
                 sizeByValue,
                 colorByValue,
                 heightByValue,
                 layerInfo.styling,
                 labelButtonList,
                 activeColorRange,
                 sizes, 
                 value, 
                 value2,
                 valueHeight,
                 divisionsColorToDisplay,
                 divisionsSize,
                 (index:number) => MapitLayerFactory.funcLabelExtractDataPairs(layerInfo, index),
                 layerInfo.styling.useClustering,
                 layerInfo.styling.clusteringType,
             );
           } else {
               // Simple maker - same for all data points.
               let markerObj;
               if (layerInfo.styling && layerInfo.styling.color) {
                 // color predetermined.
                 // find marker in the right color
                 // ToDo: currently only works with the 8 predefined colors.
                 markerObj = GenerateGeom.constructMarkerForColor(layerInfo.styling.color);
               } 
               if (!markerObj) {
                 markerObj = GenerateGeom.getNextMarker(markers);
               }
               displayColor = markerObj.name;
               let useCustomIcon:boolean = layerInfo.styling.markerType === MarkerType.Icon;
               let customIconUrl:string[] = [];
               if (useCustomIcon) {
                 customIconUrl = layerInfo.data![MitDataType.IconUrl];
               }
               console.assert(markerObj, "MarkerUTM should be set");

               layerHandle = GenerateGeom.showLayerMarkerSimple(
                layerInfo,
                 getNewId(),
                 map, 
                 pType, 
                 latOrX, 
                 lonOrY, 
                 markerObj, 
                 useCustomIcon,
                 labelButtonList,
                 customIconUrl,
                 (index:number) => MapitLayerFactory.funcLabelExtractDataPairs(layerInfo, index),
                 layerInfo.styling.useClustering);
           }
           break;

       case LayerType.AreaMunicipality:
       case LayerType.AreaMunicipalityId:
         admGeo=layerInfo.data![MitDataType.AdmReg_DK_MunicipalityId];
         v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);  
         func = defaultEqualityFunctionByCode;
         funcLabelHeader = defaultAreaLabelHeaderFunction;

         layerHandle = GenerateGeom.showLayerArea(
           getNewId(),
           v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);

         break;
       case LayerType.AreaMunicipalityName:
         admGeo=layerInfo.data![MitDataType.AdmReg_DK_MunicipalityName];
         v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);   
         func = defaultEqualityFunctionByName;
         funcLabelHeader = defaultAreaLabelHeaderFunction;


         layerHandle = GenerateGeom.showLayerArea(
           getNewId(),
           v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);

         break;


         case LayerType.AreaParish:
           case LayerType.AreaParishId:
             admGeo=layerInfo.data![MitDataType.AdmReg_DK_ParishId];
             v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);   
             func = defaultEqualityFunctionByCode;
             funcLabelHeader = defaultAreaLabelHeaderFunction;
   
   
             layerHandle = GenerateGeom.showLayerArea(
               getNewId(),
               v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
   
             break;
           case LayerType.AreaParishName:
             admGeo=layerInfo.data![MitDataType.AdmReg_DK_ParishName];
             v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);   
             func = defaultEqualityFunctionByName;
             funcLabelHeader = defaultAreaLabelHeaderFunction;
   
   
             layerHandle = GenerateGeom.showLayerArea(
               getNewId(),
               v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
   
             break;
   
           case LayerType.AreaMunicipality_RO:
           case LayerType.AreaMunicipalityId_RO:
             admGeo=layerInfo.data![MitDataType.AdmReg_RO_MunicipalityId];
             v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);   
             func = defaultEqualityFunctionByCode;
             funcLabelHeader = defaultAreaLabelHeaderFunction;
   
   
             layerHandle = GenerateGeom.showLayerArea(
               getNewId(),
               v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
   
             break;
             case LayerType.AreaMunicipalityName_RO:
             admGeo=layerInfo.data![MitDataType.AdmReg_RO_MunicipalityName];
             v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);  
             func = defaultEqualityFunctionByName;
             funcLabelHeader = defaultAreaLabelHeaderFunction;
   
             // let list = ReferenceGeomRomania.dumpMunicpalityLookupList();
             // let json = JSON.stringify(list);
             // let blob = new Blob([json], {type: "application/json"});
             // ComponentUtils.downloadBlob("romanianRefs", blob);
             layerHandle = GenerateGeom.showLayerArea(
               getNewId(),
               v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
   
             break;
             case LayerType.AreaMunicipality_DK_And_SydSlesvig:
             case LayerType.AreaMunicipalityId_DK_And_SydSlesvig:
               admGeo=layerInfo.data![MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityId];
               v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);   
               func = defaultEqualityFunctionByCode;
               funcLabelHeader = defaultAreaLabelHeaderFunction;                
               layerHandle = GenerateGeom.showLayerArea(
                 getNewId(),
                 v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);        
               break;
               case LayerType.AreaMunicipalityName_DK_And_SydSlesvig:
               admGeo=layerInfo.data![MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityName];
               v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);  
               func = defaultEqualityFunctionByName;
               funcLabelHeader = defaultAreaLabelHeaderFunction;

               layerHandle = GenerateGeom.showLayerArea(
                 getNewId(),
                 v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);        
               break;
       case LayerType.AreaRegion:
       case LayerType.AreaRegionId:
         admGeo=layerInfo.data![MitDataType.AdmReg_DK_RegionId];
         v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);  
         func = defaultEqualityFunctionByCode;
         funcLabelHeader = defaultAreaLabelHeaderFunction;


         layerHandle = GenerateGeom.showLayerArea(
           getNewId(),
           v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
         break;
       case LayerType.AreaRegionName:
         admGeo=layerInfo.data![MitDataType.AdmReg_DK_RegionName];
         v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);  
         func = defaultEqualityFunctionByName;
         funcLabelHeader = defaultAreaLabelHeaderFunction;


         layerHandle = GenerateGeom.showLayerArea(
           getNewId(),
           v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
         break;

       case LayerType.AreaZipcodes:
       case LayerType.AreaZipcodesId:
         admGeo=layerInfo.data![MitDataType.AdmReg_DK_ZipCodeId];
         v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);  
         func = defaultEqualityFunctionByCode;
         funcLabelHeader = defaultAreaLabelHeaderFunction;


         layerHandle = GenerateGeom.showLayerArea(
           getNewId(),
           v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
         break;
       case LayerType.AreaZipcodesName:
         admGeo=layerInfo.data![MitDataType.AdmReg_DK_ZipCodeName];
         v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);  
         func = defaultEqualityFunctionByName;
         funcLabelHeader = defaultAreaLabelHeaderFunction;


         layerHandle = GenerateGeom.showLayerArea(
           getNewId(),
           v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
         break;
       case LayerType.AreaCountry:
       case LayerType.AreaCountryId:
       admGeo=layerInfo.data![MitDataType.AdmReg_INT_CountryId];
       v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);  
       func = defaultEqualityFunctionByCode;
       funcLabelHeader = defaultAreaLabelHeaderFunction;


       layerHandle = GenerateGeom.showLayerArea(
         getNewId(),
         v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
       break;

       case LayerType.AreaCountryName:
         admGeo=layerInfo.data![MitDataType.AdmReg_INT_CountryName];
         v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);  
         func = defaultEqualityFunctionByName;
         funcLabelHeader = defaultAreaLabelHeaderFunction;


         layerHandle = GenerateGeom.showLayerArea(
           getNewId(),
           v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
         break;

       case LayerType.AreaCadaster:
       case LayerType.AreaProperty:
            admGeo=layerInfo.data![MitDataType.SfeEjendomsNr].map((sfe, idx) => { 
             return {
               sfeEjendomsNummer:sfe, 
               matrikelnr:layerInfo.data![MitDataType.MatrikelNr][idx], 
               ejerlavskode:layerInfo.data![MitDataType.Ejerlavskode][idx]
             };});
           let featuresCombined = (layerInfo.data![MitDataType.GeoJSON_Features]as any[]).reduce(
           (prev, curr, idx) => { 
             // skip elements without geometry
             if (curr && curr.features) {
               return [...prev, ...curr.features];
             } else {
               console.log("AreaCadaster: Skipping element without geometry");
               return prev;
             }
             }, 
           []);
           v = {"type":"FeatureCollection","crs":{"type":"name","properties":{"name":"urn:ogc:def:crs:OGC:1.3:CRS84"}},"features":featuresCombined};

           func = (codes:any[], jsonFeature:any):number => {
             let ejerlav=jsonFeature.properties.Ejerlavskode;
             let matr=jsonFeature.properties.Matrikelnummer;
             let result = codes.reduce(
               (prev, curr, idx) => {
                 if (idx > 0 && curr.matrikelnr.toString() === matr.toString() && ""+curr.ejerlavskode === ejerlav) {
                   return idx;
                 } else {
                   return prev;
                 }
               }, 
               0);
             return result;
           };
           funcLabelHeader =  (jsonFeature:any):string => {
             let ejerlav=jsonFeature.properties.Ejerlavskode;
             let matr=jsonFeature.properties.Matrikelnummer;
             let result = `${ejerlav}-${matr}`;
             return result;
           };
       
           layerHandle = GenerateGeom.showLayerArea(
             getNewId(),
             v, map, admGeo, value, title, activeColorRange, divisionsColorToDisplay, hideNoDataAreas, layerInfo.styling, func, funcLabel, callbackOnSpatialSelection);
           break;
   
        case LayerType.GeoJSON_Line:
        case LayerType.GeoJSON_Point:
        case LayerType.GeoJSON_Polygon: 
        case LayerType.GeoJSON:
          if (!layerInfo.geoJson) {
            throw new Error(`GeoJSON not set for geojson layer ${layerInfo.datasetname}`);
          }
         let geoJSON:GeoJsonImportFormat = layerInfo.geoJson;
        //  let funcLabel = (index: number) => "";

        if (layerInfo.type === LayerType.GeoJSON) {
          const PrimitiveGeoJsonLayerType = {
            "Line":   LayerType.GeoJSON_Line,
            "Point":  LayerType.GeoJSON_Point,
            "Polygon":LayerType.GeoJSON_Polygon
          }
          layerInfo.type = PrimitiveGeoJsonLayerType[Object.keys(PrimitiveGeoJsonLayerType).find((a) => geoJSON.features[0].geometry.type.includes(a)) || "Point"];
        }

         let markerObj2;
         if (layerInfo.styling && layerInfo.styling.color) {
           // color predetermined.
           // find marker in the right color
           // ToDo: currently only works with the 8 predefined colors.
           markerObj2 = GenerateGeom.constructMarkerForColor(layerInfo.styling.color);
         } 
         if (!markerObj2) {
           markerObj2 = GenerateGeom.getNextMarker(markers);
         }
        layerInfo.styling.color ??= markerObj2.name
        
        layerInfo.styling.lineOpacity ??= (layerInfo.styling.lineColor && chroma(layerInfo.styling.lineColor).alpha() !== 1) ? chroma(layerInfo.styling.lineColor).alpha() : layerInfo.styling.lineOpacity ?? 1
        layerInfo.styling.lineColor = layerInfo.styling.lineColor && chroma(layerInfo.styling.lineColor).alpha(1).css();
        let featureStyling = {...layerInfo.styling}
        const defaultFillColor = layerInfo.styling.color?.startsWith("rgb") ? layerInfo.styling.color : chroma(layerInfo.styling.color).css("rgba").replace(",1)",`,${SettingsManager.getSystemSetting("geoJSONFillOpacity", 0.1)})`)
        featureStyling.lineColor ??= markerObj2.name && chroma(markerObj2.name).alpha(1).css();
        featureStyling.areaFillColor ??= defaultFillColor; 
        
        // ToDo: Change to user modifiable to style settings.
        //  let featureStyle = GenerateGeom.styleB(displayColor, geoJSONFIllColor, geoJSONLineWeight, geoJSONFillOpacity);
         
         // if (layerInfo.styling.areaBorderPattern) {
         //   featureStyle.dashArray = layerInfo.styling.areaBorderPattern;
         //   featureStyle.stroke = true;
         // }
         // featureStyle.weight=10;

         switch (layerInfo.type) {
          case LayerType.GeoJSON_Point: {
            if (layerInfo.styling.colorByProperty || layerInfo.styling.sizeByProperty) {
              layerHandle = CreateGeoJsonMapLayers.createGeoJsonPointMapLayers(
                getNewId(),
                "blue",
                featureStyling,
                geoJSON as any,
                layerInfo
                )
            } else {
              layerHandle = CreateGeoJsonMapLayers.createGeoJsonSimpleMarker(
                getNewId(),
                map,
                "blue",
                markerObj2,
                featureStyling,
                geoJSON as any,
                layerInfo
              )
            }
            } break;
           default: {
            layerHandle = CreateGeoJsonMapLayers.createGeoJsonAreaMapLayers(
              getNewId(),
              geoJSON as any,
              featureStyling,
              "blue",
              layerInfo
            );
          } break;
         }
         
         
         break;

         case LayerType.Grid:

           callbackOnSpatialSelection && labelButtonList.push({
             function: callbackOnSpatialSelection,
             title: Localization.getText("Choose points in the area"),
             imageSrc: "glyphicon-filter",
           });

           value=layerInfo.data![MitDataType.Value];
   
           layerHandle = GenerateGeom.showLayerGrid(
             getNewId(),
             map,
             ProjectionType.UTM32,
             layerInfo.data![MitDataType.Coord_UTM32_X],
             layerInfo.data![MitDataType.Coord_UTM32_Y],
             layerInfo.data![MitDataType.GridSizeMeters],
             layerInfo.data![MitDataType.Row_Data_Quality],
             activeColorRange, 
             true,
             layerInfo.styling, 
             labelButtonList,
             value,
             divisionsColorToDisplay, 
             funcLabel
             );
           break;

       default:
         throw new Error("Unknown or unsupported layerType: "+layerInfo.type);
       }


     let newLayerInfo:LayerInfo;
       // if visible is not set, default to true;
     let colorByValueInfo = layerInfo.styling.colorByValue;
//        let colorByValueInfo = layerInfo.styling.colorByValue ? { ...layerInfo.styling.colorByValue, divisions:divisionsColor } : undefined;
     let sizeByValueInfo = layerInfo.styling.sizeByValue ? { ...layerInfo.styling.sizeByValue, divisions:divisionsSize } : undefined;
     newLayerInfo = {...layerInfo, handle:layerHandle, styling:{...layerInfo.styling}};

     return({layerHandle, layerInfo:newLayerInfo});
   } catch (error:any) {
     // ToDo: Improve Error Messages
     Logger.logError("MapScreen", "addALayer", error.message);
     throw Utils.createErrorEventObject(error.message);
    //  appMessageDispatch(actionSetErrorMessage(error.message));
     return({layerHandle:undefined, layerInfo});
   }
 }

   /**
   * getGeometryFromLayerInfo
   * 
   * @param layerInfo The layerInfo for the layer to create
   */
   static getGeometryFromLayerInfo(layerInfo:LayerInfo):any {
     let jsonFromSheet:{[header:string]:any}[] = SheetFunc.sheetToJson(layerInfo.datasheet);
    return MapitLayerFactory.getGeometryFromLayerInfoAndJSONData(layerInfo, jsonFromSheet)
   }

  static getAdmGeomKeyFromAreaLayerType(layerType: LayerType): MitDataType | null {
    const admGeoKey = {
      [LayerType.AreaMunicipality]: MitDataType.AdmReg_DK_MunicipalityId,
      [LayerType.AreaMunicipalityId]: MitDataType.AdmReg_DK_MunicipalityId,
      [LayerType.AreaMunicipalityName]: MitDataType.AdmReg_DK_MunicipalityName,
      [LayerType.AreaParish]: MitDataType.AdmReg_DK_ParishId,
      [LayerType.AreaParishId]: MitDataType.AdmReg_DK_ParishId,
      [LayerType.AreaParishName]: MitDataType.AdmReg_DK_ParishName,
      [LayerType.AreaMunicipality_RO]: MitDataType.AdmReg_RO_MunicipalityId,
      [LayerType.AreaMunicipalityId_RO]: MitDataType.AdmReg_RO_MunicipalityId,
      [LayerType.AreaMunicipalityName_RO]:MitDataType.AdmReg_RO_MunicipalityName,
      [LayerType.AreaMunicipality_DK_And_SydSlesvig]:MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityId,
      [LayerType.AreaMunicipalityId_DK_And_SydSlesvig]:MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityId,
      [LayerType.AreaMunicipalityName_DK_And_SydSlesvig]:MitDataType.AdmReg_DK_And_Sydslesvig_MunicipalityName,
      [LayerType.AreaRegion]: MitDataType.AdmReg_DK_RegionId,
      [LayerType.AreaRegionId]: MitDataType.AdmReg_DK_RegionId,
      [LayerType.AreaRegionName]: MitDataType.AdmReg_DK_RegionName,
      [LayerType.AreaZipcodes]: MitDataType.AdmReg_DK_ZipCodeId,
      [LayerType.AreaZipcodesId]: MitDataType.AdmReg_DK_ZipCodeId,
      [LayerType.AreaZipcodesName]: MitDataType.AdmReg_DK_ZipCodeName,
      [LayerType.AreaCountry]: MitDataType.AdmReg_INT_CountryId,
      [LayerType.AreaCountryId]: MitDataType.AdmReg_INT_CountryId,
      [LayerType.AreaCountryName]: MitDataType.AdmReg_INT_CountryName,
    };

    return admGeoKey[layerType] || null
  }

  static getAdmGeomByCodeFromAreaLayerType(layerType: LayerType): boolean {
    const admGeoKey = {
      [LayerType.AreaMunicipality]: true,
      [LayerType.AreaMunicipalityId]: true,
      [LayerType.AreaMunicipalityName]: false,
      [LayerType.AreaParish]: true,
      [LayerType.AreaParishId]: true,
      [LayerType.AreaParishName]: false,
      [LayerType.AreaMunicipality_RO]: true,
      [LayerType.AreaMunicipalityId_RO]: true,
      [LayerType.AreaMunicipalityName_RO]: false,
      [LayerType.AreaMunicipality_DK_And_SydSlesvig]:true,
      [LayerType.AreaMunicipalityId_DK_And_SydSlesvig]:true,
      [LayerType.AreaMunicipalityName_DK_And_SydSlesvig]: false,
      [LayerType.AreaRegion]: true,
      [LayerType.AreaRegionId]: true,
      [LayerType.AreaRegionName]: false,
      [LayerType.AreaZipcodes]: true,
      [LayerType.AreaZipcodesId]: true,
      [LayerType.AreaZipcodesName]: false,
      [LayerType.AreaCountry]: true,
      [LayerType.AreaCountryId]: true,
      [LayerType.AreaCountryName]: false,
    };

    return admGeoKey[layerType] ?? false
  }

  /**
   * getGeometryFromLayerInfo
   * 
   * @param layerInfo The layerInfo for the layer to create
   * @param jsonFromSheet The data to add...
   */
  static getGeometryFromLayerInfoAndJSONData(layerInfo:LayerInfo, jsonFromSheet:{[header:string]:any}[]):any {

    let admGeo:any[];
    let v;

  try {

     switch(layerInfo.type) {
         case LayerType.PointWGS84:
         case LayerType.PointUTM32:

           let pType:ProjectionType;
           let lat:any[]=[];
           let lon:any[]=[];
           if(layerInfo.type === LayerType.PointWGS84) {
             pType=ProjectionType.WGS84;
             lat=layerInfo.data![MitDataType.Coord_WGS84_Lat];
             lon=layerInfo.data![MitDataType.Coord_WGS84_Lon];
           } else { 
             // Then it is LayerType.PointUTM32
             pType=ProjectionType.UTM32;
             layerInfo.data![MitDataType.Coord_UTM32_Y].forEach((itm,idx) => {
               let x=layerInfo.data![MitDataType.Coord_UTM32_X][idx];
               let y=layerInfo.data![MitDataType.Coord_UTM32_Y][idx];
               if (x && y) {
                 let coords = GenerateGeomUtils.convertFromUTMtoLatLng2([Number(x),Number(y)]);
                 lat.push(coords[0]);
                 lon.push(coords[1]);
               } else {
                 lat.push(0);
                 lon.push(0);
               }
             })
           }

           let features = lat.map((lat2, idx) => {
            let lon2 = lon[idx];
              return lat2 && lon2 && turf.point([lon2, lat2], jsonFromSheet[idx]);
           })

           return turf.featureCollection(features.filter((a) => a));
          break;

       case LayerType.AreaCadaster:
        //TODO: only get Cadaster
       case LayerType.AreaProperty:
            admGeo=layerInfo.data![MitDataType.SfeEjendomsNr].map((sfe, idx) => { 
             return {
               sfeEjendomsNummer:sfe, 
               matrikelnr:layerInfo.data![MitDataType.MatrikelNr][idx], 
               ejerlavskode:layerInfo.data![MitDataType.Ejerlavskode][idx],
               ...jsonFromSheet[idx]
             };});
           let featuresCombined = (layerInfo.data![MitDataType.GeoJSON_Features]as any[]).reduce(
           (prev, curr, idx) => { 
             // skip elements without geometry
             if (curr && curr.features) {
               // add information to properties.
              // combinedInfo = { ...curr.}
              let featuresCombinedProperties = curr.features.map((ft) => { 
                return {
                  ...ft,
                  properties: {...ft.properties, ...jsonFromSheet[idx]}
                }
              })
               return [...prev, ...featuresCombinedProperties];
             } else {
               console.log("AreaCadaster: Skipping element without geometry");
               return prev;
             }
             }, 
           []);
           v = {"type":"FeatureCollection","crs":{"type":"name","properties":{"name":"urn:ogc:def:crs:OGC:1.3:CRS84"}},"features":featuresCombined};
             return v;
           break;
   
        case LayerType.GeoJSON_Line:
        case LayerType.GeoJSON_Point:
        case LayerType.GeoJSON_Polygon: 
        case LayerType.GeoJSON:
          const geoJSON:GeoJsonImportFormat = layerInfo.data![MitDataType.GeoJSON_Features_String];
          const crs = geoJSON.crs
          const cleanedFeature = geoJSON.features.filter((a) => a.geometry)
          if (crs) {
            const convertedFeatures = GenerateGeomUtils.convertGeoJson(turf.featureCollection(cleanedFeature as any), crs)
            return convertedFeatures;
          }
          return turf.featureCollection(cleanedFeature as any);

         break;

         case LayerType.Grid:

             let projectionType = ProjectionType.UTM32;
            let coord1 = layerInfo.data![MitDataType.Coord_UTM32_X];
            let coord2= layerInfo.data![MitDataType.Coord_UTM32_Y];
            let sizeM = layerInfo.data![MitDataType.GridSizeMeters];
            let featuresArray: turf.Feature[] = [];
  
            for (let j = 0; j < coord1.length; j++) {
        
                let sw:number[];
                let ne:number[];
                let nw:number[];
                let se:number[];
                let coords;
                if (projectionType === ProjectionType.UTM32) {
                  let size = sizeM[j];
                  coords = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j], coord2[j]]);
                  sw = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j], coord2[j]]).reverse();
                  ne = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j] + size, coord2[j] + size]).reverse();
                  nw = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j] + size, coord2[j] ]).reverse();
                  se = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j], coord2[j] + size]).reverse();
                } else {
                  throw Error("Only UTM32 is supported");
                }
        
                let lat = coords[0];
                let lon = coords[1];
        
                let latlongs = [sw, nw, ne, se, sw];
                let feature = {
                  type: "Feature",
                  properties: {
                    ...jsonFromSheet[j]
                  },
                  "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                      latlongs
                    ]
                  },
                };
        
                featuresArray.push(feature as turf.Feature);
            }
          return turf.featureCollection(featuresArray)
           break;

       default:
            // All (prefedined) Area Type layers are handled here. I.e. Municipality, Postal Area, ...
          let admGeoKey = this.getAdmGeomKeyFromAreaLayerType(layerInfo.type)
          if (admGeoKey) {
            admGeo=layerInfo.data![admGeoKey];
            let byCode = this.getAdmGeomByCodeFromAreaLayerType(layerInfo.type)
            v = MapitLayerFactory.combineDataAndGeom(layerInfo, byCode, admGeo, jsonFromSheet);
            return v;
          }
         throw new Error("Unknown or unsupported layerType: "+layerInfo.type);
       }


   } catch (error:any) {
     // ToDo: Improve Error Messages
     Logger.logError("MapScreen", "getGeometryFromLayerInfo", error.message);
     throw Utils.createErrorEventObject(error.message);
    //  appMessageDispatch(actionSetErrorMessage(error.message));
     return({layerHandle:undefined, layerInfo});
   }
 }

  static reCombineDataAndGeom(layerInfo: {type: LayerType, importTransformation?:ImportTransformation}, byCode: boolean, admGeo: any[], jsonFromSheet: { [key: string]: any; }[]) {
    return this.combineDataAndGeom(layerInfo,byCode,admGeo,jsonFromSheet)
  }

  private static combineDataAndGeom(layerInfo: {type: LayerType, importTransformation?:ImportTransformation}, byCode: boolean, admGeo: any[], jsonFromSheet: { [key: string]: any; }[]) {
    let v = ReferenceGeomComposite.getReferenceGeo(layerInfo.type);

    let newfeatures = v.features.map((ft) => {
      let idx = -1;
      let props: any = {};
      let featureCopy: typeof ft = structuredClone(ft)
      let code = byCode ? ReferenceGeomComposite.selectCodeElement(layerInfo.type, featureCopy) : ReferenceGeomComposite.selectNameElement(layerInfo.type, featureCopy);
      let hits = admGeo.map((val, idx) => ReferenceGeomComposite.equalityFunc(layerInfo.type)(val, code) ? idx : -1).filter((i) => i != -1);
      if (hits.length === 0) {
        // not found
        props = { VIAMAP_NO_DATA_FOR_AREA: true };
      } else {
          if (layerInfo.importTransformation && layerInfo.importTransformation.action != ImportTransformationAction.None) {
          if (hits.length > 0) {
              if (layerInfo.importTransformation.action === ImportTransformationAction.DuplicatesThrowAway) {
                idx = hits[0];
                props = jsonFromSheet[idx];
              } else {
                // Combine the properties in some way
                idx = hits[0];
                props = jsonFromSheet[idx]; // all properties default to the first instance of duplicated rows.
                if (layerInfo.importTransformation.propertyToTransform && layerInfo.importTransformation.transformation) {
                  let valuesToTransform = hits.reduce<any[]>((result:any[], curr:number) => {
                    let p = jsonFromSheet[curr];
                    let val = p[layerInfo.importTransformation!.propertyToTransform!];
                    return [...result, val]; 
                  }, []);
                  let newValue;
                  switch(layerInfo.importTransformation.transformation) {
                    case ImportTransformationType.Sum:
                      newValue = valuesToTransform.reduce((result, curr) => {
                        return result + curr;
                      }, 0);
                      break;
                    case ImportTransformationType.Min: 
                      newValue = Math.min(...valuesToTransform);
                      break;
                    case ImportTransformationType.Max: 
                      newValue = Math.max(...valuesToTransform);
                      break;
                    case ImportTransformationType.Avg: 
                      let sum = valuesToTransform.reduce((result, curr) => {
                        return result + curr;
                      }, 0);
                      newValue = sum / valuesToTransform.length;
                      break;
                    case ImportTransformationType.Count:
                      newValue = valuesToTransform.length;
                      break;
                    case ImportTransformationType.Concatenate:
                      newValue = valuesToTransform.reduce((result, curr, i) => {
                        return result + (i>0 && layerInfo.importTransformation?.concatenationSeparator ? layerInfo.importTransformation?.concatenationSeparator : "") + curr;
                      }, "");
                      break;
                    default: 
                      throw new Error(`ImportTransformation. Unknown transformation type: ${layerInfo.importTransformation.transformation}`)                      
                  }
                  // overwrite selected poperty with the computed value.
                  props[layerInfo.importTransformation!.propertyToTransform!]=newValue;
                  props["VIAMAP_COUNT_OF_DUPLICATES"] = valuesToTransform.length;
                }
              }
            }
            } else {
              if (hits.length === 1) {
                idx = hits[0];
                props = jsonFromSheet[idx];
                if (!props) {
                  throw new Error(`Properties for index ${idx} not found`);
                }
              }
              if (hits.length > 1) {
                let line = hits[1]+2; // adds one extra for header line.
                let errorMessage = Localization.getFormattedText("Duplicate area code/name found for {code}. Line: {line} Count: {count}", {
                  code:code, line:line, count: hits.length
                });
                throw new Error(errorMessage);
              }
            }
      }
    return { ...featureCopy, properties: { ...featureCopy.properties, ...props } };
    });
    admGeo.forEach((val) => {
      // find matching feature - as check of completeness
      let idx = v.features.findIndex((ft) => {
          let code = byCode ? ReferenceGeomComposite.selectCodeElement(layerInfo.type, ft) : ReferenceGeomComposite.selectNameElement(layerInfo.type, ft);
          return ReferenceGeomComposite.equalityFunc(layerInfo.type)(val, code);
      });
      if (idx === -1) {
        // ToDo: Add to list of import errors instead
        console.error(`Properties for key ${val} not found`);
      }
    })
    return {
      type: "FeatureCollection",
      features: newfeatures
    };
  }

 static zoomToFeature(layerHandle:any) {
  throw new Error("Not implemented");
 }

 /**
  * Brings a marker to the front of the map (z-index). 
  * 
  * Does nothing for other layer types nor for permanent markers.
  * @param layerId Id of the LayerInfo object.
  */
 static bringMarkerToFront(layerId: number, state:any): void {
   const scaleFactor = 33;

   // Find front-most marker and increment.
   let zIndexToSet = 0;
   let allLayerIds = Object.keys(state.layers);
   
   allLayerIds.forEach(currentlayerId => {
     let currentLayerInfo = state.layers[currentlayerId];
     if (currentLayerInfo.type === LayerType.PointWGS84 && currentLayerInfo.layerVariability !== LayerVariabilityType.FixedFeatureLayer) {
       try {
         let leafletLayer = currentLayerInfo.handle._layers;

         if (!leafletLayer) {
           throw new Error("Leaflet layer not found");
         }

         let currentZIndex = leafletLayer[Object.keys(leafletLayer)[0]].options.zIndexOffset;
         
         if (currentZIndex) {
           zIndexToSet = currentZIndex > zIndexToSet ? currentZIndex : zIndexToSet;
         }
       } catch (error:any) {
         Logger.logError("Mapscreen", "bringMarkerToFront", error.message);
       }
     }
   });

   if (state.layers[layerId] && state.layers[layerId].layerVariability !== LayerVariabilityType.FixedFeatureLayer) {
     MapitLayerFactory.setMarkerZIndexOffset(layerId, zIndexToSet + scaleFactor);
   }
 }

 /**
  * Resets the z-index offset of a marker.
  * @param layerId Id of the marker's layer info.
  */
 static resetMarkerZIndexOffset(layerId: number): void {
   throw new Error("Not implemented");
   // try {
   //   let leafletLayers = state.layers[layerId].handle._layers;
     
   //   if (!leafletLayers) {
   //     throw new Error("Leaflet layer not found");
   //   }

   //   let layerIds = Object.keys(leafletLayers);
   //   layerIds.forEach(leafletLayerId => {
   //     leafletLayers[leafletLayerId]._resetZIndex && leafletLayers[leafletLayerId]._resetZIndex();
   //   });

   // } catch (error:any) {
   //   Logger.logError("Mapscreen", "resetMarkerZIndexOffset", error.message);
   // }
 }

 /**
  * Sets the z-index offset of a marker.
  * Increments of 30 or higher is recommended.
  * @param layerId Id of the marker's layer info.
  * @param zIndexOffset New z-index offset value as a number.
  */
 static setMarkerZIndexOffset(layerId: number, zIndexOffset: number): void {
   throw new Error("Not implemented");
   // try {
   //   let layerInfo = state.layers[layerId];
   //   let leafletLayers = layerInfo.handle._layers;

   //   if (!leafletLayers) {
   //     throw new Error("Leaflet layer not found");
   //   }

   //   let layerIds = Object.keys(leafletLayers);
   //   layerIds.forEach(leafletLayerId => {
   //     leafletLayers[leafletLayerId].setZIndexOffset && leafletLayers[leafletLayerId].setZIndexOffset(zIndexOffset);
   //   });

   // } catch (error:any) {
   //   Logger.logError("Mapscreen", "setMarkerZIndexOffset", error.message);
   // }
 }

}